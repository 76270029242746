<script>
import AppLayout from '@/layouts/backEnd/AppLayout.vue'
import VueMultiselect from 'vue-multiselect'
import {QuillEditor} from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import Loader from "@/components/backEnd/mixins/Loader";
import ShowToastMessage from "@/components/backEnd/mixins/ShowToastMessage";
import {mapActions, mapGetters} from 'vuex';
import Authorization from "@/components/backEnd/mixins/Authorization";


export default {
  components: {
    AppLayout,
    VueMultiselect,
    QuillEditor

  },
  mixins: [ShowToastMessage, Loader, Authorization],
  name: "RefundCreate",
  data() {
    return {
      placeHolder: 'Enter Refund Note',
      postRefundData: {
        processed_by: '',
        paid_to: '',
        reference: '',
        payment_method: '',
        payment_gateway: '',
        payment_gateway_id: '',
        reason: '',
        amount: '',
        status: 0, // 0: pending,
      },
      selectedMethod: {
        value: '',
        name: 'None'
      },
      selectedReason: {
        value: '',
        name: 'None'
      },
      selectedGateway: {
        value: '',
        name: 'None'
      },
      selectedUserType: {
        value: '',
        name: 'None'
      },
      selectedRefundNoteType: {
        value: '',
        name: 'None'
      },
      selectedReferenceType: {
        value: '',
        name: 'None'
      },
      selectedGatewayId: {
        value: '',
        name: 'None'
      },
      referenceTypeOption: [
        {
          value: 1,
          name: 'Order'
        },
        {
          value: 2,
          name: 'Appointment'
        },
      ],
      have_note: false,
      getSettingParams: {
        type: ['default', 'refund'],
        key: [
          'default_customer_type',
          'refund_note_type'
        ],
      },
      description: '',
      postRefundNoteData: {
        user_id: '',
        refund_id: '',
        user_type: '',
        type: '',
        description: '',
      },
      postRefundId: '',
      errors: {
        refund: {
          paid_to: '',
          reference: '',
          payment_method: '',
          reason: '',
          amount: '',
        },
        refundNote: {
          user_id: '',
          refund_id: '',
          user_type: '',
          type: '',
          description: ''
        }
      },
      isGatewayVisible: true,
      order: {},
      appointment: {},
      refundObj: [],
      selectedMaxAmount: 0,
      placeholderAmount: 0,
      referenceNo: '',
      methodOptions: [],
      gatewayOptions: [],
      gatewayIdOptions: [],
      isMethodOptionShow: false,
      isGatewayOptionsShow: false,
      isGatewayIdOptionsShow: false,
      userId: null,
      isValidAmount: false,
    }
  },
  computed: {
    ...mapGetters({
      previousRoute: 'previousRoute',
      authUser: 'appAuthLogin/authUser',
      userType: 'appUsers/userType',
    }),
    currentUserTypeOptions() {
      return [{value: '', name: 'None'}, ...this.userType];
    },
    reasonOptions() {
      const reasonObj = {
        0: 'Incorrect Amount Charged',
        1: 'Duplicate Charge',
        2: 'Tech Poor Service',
        3: 'No Service Provided',
        4: 'Agent Error',
        5: 'Cancelled Appointment',
        6: 'Cancelled Product Order',
        7: 'Cancelled Repair Service',
        8: 'Other'
      }
      let reason = Object.entries(reasonObj).map(([key, value]) => {
        return {value: key, name: value};
      });
      return [{value: '', name: 'None'}, ...reason];
    },
    // same as refund reasons
    /*refundNoteTypeOptions() {
      let noteType = this.$store.getters["appSettings/settingRefundNoteType"].value;
      return [{value: '', name: 'None'}, ...noteType];
    },*/

    isRefundNoteDataFiled() {
      return !!(this.have_note && this.selectedUserType.value !== '' && this.description !== '' && this.selectedReason.value !== '');
    },
    /*gatewayOptions() {
      return [{value: '', name: 'None'}, {value: 'simplify', name: 'Simplify'}]
    },*/
    isReferenceAndTypeFiled() {
      return (this.selectedReferenceType.value !== '' && this.referenceNo !== '');
    },


  },
  watch: {
    selectedReferenceType(cru, pre) {
      console.log(cru, pre)
      if (pre.value !== '') {
        this.resetAllListFilter();
      }
    },
    selectedMethod() {
      this.gatewayOptions = [];
      this.gatewayIdOptions = [];
      this.selectedMaxAmount = 0;
      this.placeholderAmount = 0;
      let method = this.selectedMethod.value;
      this.postRefundData.payment_method = method;

      // check method type card
      if (method === 1) {

        let filterByCard = this.refundObj.filter(({type}) => type === 'card').map((obj) => obj['paymentGateway']);
        let listOfGateway = this.removeDuplicate(filterByCard, 'value');
        this.gatewayOptions = [...listOfGateway];
        this.isGatewayOptionsShow = true;

      }
      // check method type afterPay
      if (method === 2) {

        let filterByCard = this.refundObj.filter(({ type }) => type === 'afterpay').map((obj) => obj['paymentGateway']);
        console.log(filterByCard, "GA-X59Z75-A");
        let listOfGateway = this.removeDuplicate(filterByCard, 'value');
        this.gatewayOptions = [...listOfGateway];
        this.isGatewayOptionsShow = true;

      }
      if (method === 0) {
        // check method type cash
        this.isGatewayOptionsShow = false;
        this.isGatewayIdOptionsShow = false;
        this.selectedMaxAmount = this.refundObj.find(({type}) => type === 'cash').amount;
        this.placeholderAmount = parseFloat(this.selectedMaxAmount / 100).toFixed(2);
        this.selectedGateway = {
          value: '',
          name: 'None'
        };
        this.selectedGatewayId = {
          value: '',
          name: 'None'
        };
      }
    },

    selectedReason() {
      this.postRefundData.reason = this.selectedReason.value;
      // refund reason and refund note type are same.
      this.selectedRefundNoteType.value = this.selectedReason.value;
    },
    have_note: {
      handler(currentValue) {
        if (currentValue === true) {
          this.selectedUserType = this.currentUserTypeOptions.length === 2 ? this.currentUserTypeOptions[1] : this.currentUserTypeOptions[0];
        }
      }
    },
    selectedGateway() {
      let gateway = this.selectedGateway.value;
      console.log(gateway);
      this.gatewayIdOptions = gateway !== '' ? this.refundObj.filter(({type}) => (type === 'card' || type === 'afterpay') ).map((obj) => {
        if (obj['paymentGateway'].value === gateway) {
          return obj.paymentGatewayId
        }
      }) : [];
      this.isGatewayIdOptionsShow = this.gatewayIdOptions.length !== 0;

      this.postRefundData.payment_gateway = this.selectedGateway.value;
    },
    selectedGatewayId() {
      let gatewayId = this.selectedGatewayId.value;
      console.log(gatewayId);
      if (gatewayId !== '') {
        this.selectedMaxAmount = this.refundObj.filter((obj) => (obj.type === 'card' || obj.type === 'afterpay') && obj.paymentGatewayId.value === gatewayId).map((obj) => {
          return obj.paymentAmount.amount
        })[0];
        this.placeholderAmount = parseFloat(this.selectedMaxAmount / 100).toFixed(2);

        this.postRefundData.payment_gateway_id = gatewayId;
      }

    },
    'postRefundData.amount': {
      handler(currentValue) {
        console.log(currentValue)
        this.isValidAmount = true;
        let maxAmount = parseFloat(this.selectedMaxAmount / 100).toFixed(2)
        console.log(maxAmount);
        if (parseFloat(currentValue) > maxAmount) {
          this.isValidAmount = false;
          this.errors.refund.amount = `amount should be greater than 0 and not more than ${maxAmount}`;
          /*this.showToastMessage({
            type: 'warning',
            message: `amount should be greater than 0 and not more than ${maxAmount}`
          });*/
          //currentValue = 0;
        }
        if (parseFloat(currentValue) <= 0 || currentValue === '') {
          this.isValidAmount = false;
          this.errors.refund.amount = `amount should be greater than 0`;
          /*this.showToastMessage({
            type: 'warning',
            message: 'amount should be greater than 0'
          });*/
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getSettings: 'appSettings/getSettings',
      getUserType: 'appUsers/getUserType',
      getOrderByReference: 'appOrders/getOrders',
      getAppointmentByReference: 'appAppointments/getAppointments',

      postRefund: 'appRefunds/postRefund',
      postRefundNote: 'appRefundNotes/postRefundNote',
    }),

    removeDuplicate(array, arrayKey) {
      const uniqueIds = new Set();

      return array.filter(element => {
        const isDuplicate = uniqueIds.has(element[`${arrayKey}`]);
        uniqueIds.add(element[`${arrayKey}`]);
        return !isDuplicate;
      });
    },

    resetAllListFilter() {
      this.referenceNo = '';

      this.isMethodOptionShow = false;
      this.isGatewayOptionsShow = false;
      this.isGatewayIdOptionsShow = false;

      this.selectedMethod = {
        value: '',
        name: 'None'
      };
      this.selectedGateway = {
        value: '',
        name: 'None'
      };
      this.selectedGatewayId = {
        value: '',
        name: 'None'
      };
      this.selectedReason = { value: '', name: 'None' };

      this.gatewayOptions = [];
      this.gatewayIdOptions = [];
      this.methodOptions = [];

    },

    buildRefundObj(parentObj, paymentRelationName) {
      let cardData = [];
      // let afterPayData = [];
      let amount = 0;
      let isCardDataFound = false;
      let isAfterPayDataFound = false;
      parentObj[`${paymentRelationName}`].forEach((singlePayment) => {
        // if any type of payment found, add cash option to payment method option & shown
        if (singlePayment.payment !== null) {
          amount = amount + singlePayment.payment.total_transaction;
        }
        // if card type payment found , add card option to payment method option & shown
        if (singlePayment.payment.type === 'Card' && singlePayment.payment.cardPayment !== null && singlePayment.payment.cardPayment.status === 'Paid') {
          isCardDataFound = true;
          let card = {
            type: 'card',
            paymentGateway: {
              name: singlePayment.payment.cardPayment.payment_gateway,
              value: singlePayment.payment.cardPayment.payment_gateway.toLowerCase(),
            },
            paymentGatewayId: {
              name: singlePayment.payment.cardPayment.payment_gateway_id,
              value: singlePayment.payment.cardPayment.payment_gateway_id,
            },
            paymentAmount: {
              amount: singlePayment.payment.total_transaction
            }
          }
          cardData.push(card);
        }
        // if afterPay type payment found , add afterPay option to payment method option & shown
        if (singlePayment.payment.type === 'Afterpay' && singlePayment.payment.afterpayPayment !== null && singlePayment.payment.afterpayPayment.status === 'Paid') {
          isAfterPayDataFound = true;
          let afterPay = {
            type: 'afterpay',
            paymentGateway: {
              name: singlePayment.payment.type,
              value: singlePayment.payment.type.toLowerCase(),
            },
            paymentGatewayId: {
              name: singlePayment.payment.afterpayPayment.payment_gateway_id,
              value: singlePayment.payment.afterpayPayment.payment_gateway_id,
            },
            paymentAmount: {
              amount: singlePayment.payment.total_transaction
            }
          }
          cardData.push(afterPay);
        }
      });
      // if card data found then push card method option
      if (isCardDataFound) {
        this.methodOptions.push({
          value: 1,
          name: 'Card'
        });
      }
      // if is AfterPay Data Found   then push card method option
      if (isAfterPayDataFound) {
        this.methodOptions.push({
          value: 2,
          name: 'AfterPay'
        });
      }
      // default cash method option
      this.methodOptions.push({
        value: 0,
        name: 'Cash'
      });
      cardData.push(
          {
            type: 'cash',
            amount: amount
          }
      );
      this.isMethodOptionShow = cardData.length !== 0;
      console.log(cardData)
      return cardData;
    },

    async getSettingList(settingParams) {
      await this.getSettings(settingParams);
    },

    async getCurrentUserTypes(id) {
      await this.getUserType(id);
    },

    async getSingleOrderByReference(referenceNo) {
      const params = {
        order_no: referenceNo,
        status: 1, // 1 for processing order
        with_relation: ['user', 'orderPayments.payment.cardPayment','orderPayments.payment.afterpayPayment']
      };
      this.loader(true);
      this.postRefundData.paid_to = this.user

      await this.getOrderByReference(params).then((response) => {
        if (response && response.status && response.status === 200) {
          this.order = this.$store.getters['appOrders/orders'];
          this.loader(false);
          // check order data is available or not
          if (this.order.length !== 0 &&  (this.order[0].order_no === referenceNo)) {
           
              this.userId = this.order[0].user.id;
              this.postRefundData.reference = this.order[0].order_no;
              this.refundObj = this.buildRefundObj(this.order[0], 'orderPayments');
              return;
          
          
          } else {
            this.selectedGateway = { value: '', name: 'None' };
            this.selectedGatewayId = { value: '', name: 'None' };
            this.selectedMethod = { value: '', name: 'None' };
            this.selectedReason = { value: '', name: 'None' };
            this.selectedRefundNoteType = { value: '', name: 'None' };
            this.placeholderAmount = 0;
            this.placeHolder = "";
            this.isGatewayIdOptionsShow = false;
            this.isGatewayOptionsShow = false;
            this.isGatewayVisible = false;
            this.isMethodOptionShow = false;
            this.postRefundData.amount = "";
            this.showToastMessage({type: 'error', 'message': 'No record available on order by this reference'});
          }

        } else {
          // if request not successfully done
          this.loader(false);
          this.showToastMessage(response);
        }
      });
    },

    async getSingleAppointmentByReference(referenceNo) {
      const params = {
        reference: referenceNo,
        with_relation: [
          'customer',
          'customer.user',
          'appointmentPayments.payment.cardPayment',
          'appointmentPayments.payment.afterpayPayment'
        ]
      };

      this.loader(true);
      return await this.getAppointmentByReference(params).then((response) => {
        if (response && response.status && response.status === 200) {
          this.loader(false);
          this.appointment = this.$store.getters['appAppointments/appointments'];
          // check order data is available or not
          if (this.appointment.length !== 0 && (this.appointment[0].reference === referenceNo)) {
            this.userId = this.appointment[0].customer.user.id;
            this.postRefundData.reference = this.appointment[0].reference;
            this.refundObj = this.buildRefundObj(this.appointment[0], 'appointmentPayments');
          } else {
            this.selectedGateway = { value: '', name: 'None' };
            this.selectedGatewayId = { value: '', name: 'None' };
            this.selectedMethod = { value: '', name: 'None' };
            this.selectedReason = { value: '', name: 'None' };
            this.selectedRefundNoteType = { value: '', name: 'None' };
            this.placeholderAmount = 0;
            this.placeHolder = "";
            this.isGatewayIdOptionsShow = false;
            this.isGatewayOptionsShow = false;
            this.isGatewayVisible = false;
            this.isMethodOptionShow = false;
            this.postRefundData.amount = "";
            this.showToastMessage({type: 'error', 'message': 'No record available on appointment by this reference'});
          }
        } else {
          // if request not successfully done
          this.loader(false);
          this.showToastMessage(response);
        }
      });
    },

    async createSingleRefundNote() {

      const data = {
        user_id: this.authUser.id,
        refund_id: this.postRefundId,
        user_type: this.selectedUserType.value ?? '',
        type: this.selectedRefundNoteType.value ?? '',
        description: this.description
      }

      await this.postRefundNote(data).then((response) => {
        if (response.status === 201) {
          return;
        }

        this.errors.refundNote = {
          user_id: response?.errors?.user_id?.[0] ?? '',
          refund_id: response?.errors?.refund_id?.[0] ?? '',
          user_type: response?.errors?.user_type?.[0] ?? '',
          type: response?.errors?.type?.[0] ?? '',
          description: response?.errors?.description?.[0] ?? '',
        }

        if (response.message) {
          this.showToastMessage(response);
        }
      });

    },

    async createSingleRefund() {
      if (!this.isReferenceAndTypeFiled || !this.isValidAmount) {
        return;
      }
      this.postRefundData.paid_to = this.userId;
      const dataObj = {...this.postRefundData, amount: this.postRefundData.amount * 100};
      this.loader(true);
      await this.postRefund(dataObj).then(async (response) => {
        if (response.status === 201) {
          this.postRefundId = this.$store.getters['appRefunds/refund'].id;

          if (this.isRefundNoteDataFiled && this.postRefundId) {
            await this.createSingleRefundNote();
          }
          const toastObj = {
            message: 'Refund created successfully.',
            type: 'success'
          };

          this.showToastMessage(toastObj);
          if(this.can('refund-view-any')){
            await this.$router.push({
              name: 'appRefundList',
            });
            return;
          }
          this.$router.go(this.$route.name);


        }

        this.errors.refund = {
          paid_to: response?.errors?.paid_to?.[0] ?? '',
          reference: response?.errors?.reference?.[0] ?? '',
          payment_method: response?.errors?.payment_method?.[0] ?? '',
          payment_gateway: response?.errors?.payment_gateway?.[0] ?? '',
          payment_gateway_id: response?.errors?.payment_gateway_id?.[0] ?? '',
          reason: response?.errors?.reason?.[0] ?? '',
          amount: response?.errors?.amount?.[0] ?? '',
        }

        if (response.message) {
          this.showToastMessage(response);
        }

      });
      this.loader(false);
    },

    async getReference($event) {
      let reference = $event.target.value;

      // initial method option empty
      this.methodOptions = [];
      if (this.isReferenceAndTypeFiled && reference !== '') {
        //let reference = this.postRefundData.reference;
        this.selectedReferenceType.value === 1 ?
            await this.getSingleOrderByReference(reference) :
            await this.getSingleAppointmentByReference(reference);
      }
    }

  },

  async mounted() {
    await this.getSettingList(this.getSettingParams);
    const currentUserId = this.$store.getters["appAuthLogin/authUser"].id;
    await this.getCurrentUserTypes(currentUserId);
  }
}
</script>

<template>
  <AppLayout>
    <template v-slot:appContent>
      <div>
        <div class="d-sm-flex justify-content-between align-items-center pt-2 pt-md-2 pt-xl-0 ">
          <h4 class="">Refunds/Create</h4>
          <div>
            <router-link :to="{...previousRoute}">
              <span class="glow d-flex align-items-center"><i class='bx bx-undo'></i>&nbsp;Back</span>
            </router-link>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <form action="">
              <div class="row">
                <div class="col-12 col-sm-2">
                  <div class="form-group">
                    <div class="controls">
                      <label>Reference Type</label>
                      <VueMultiselect :allowEmpty="false" v-model="selectedReferenceType" class="multiselect-blue"
                                      :options="referenceTypeOption"
                                      :close-on-select="true" placeholder="Select Type" label="name"
                                      track-by="name"
                                      :show-labels="false"/>
                    </div>
                    <div class="text-danger" v-if="errors.refund.payment_method">{{
                        errors.refund.payment_method
                      }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-3">
                  <div class="form-group">
                    <div class="controls">
                      <label>Referral Code</label>
                      <input v-model="referenceNo" v-on:blur="getReference($event)"
                             v-on:keyup.enter="getReference($event)" type="text"
                             class="form-control"
                             placeholder="Enter reference no"
                             name="referralCode">
                    </div>
                    <div class="text-danger" v-if="errors.refund.reference">{{ errors.refund.reference }}</div>
                  </div>
                </div>

                <div class="col-12 col-sm-2" v-show="isMethodOptionShow">
                  <div class="form-group">
                    <div class="controls">
                      <label>Payment Method</label>
                      <VueMultiselect :allowEmpty="false" v-model="selectedMethod" class="multiselect-blue"
                                      :options="methodOptions"
                                      :close-on-select="true" placeholder="Select Payment Method" label="name"
                                      track-by="name"
                                      :show-labels="false"/>
                    </div>
                    <div class="text-danger" v-if="errors.refund.payment_method">{{ errors.refund.payment_method }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-2" v-show="isMethodOptionShow && isGatewayOptionsShow">
                  <div class="form-group">
                    <div class="controls">
                      <label>Payment Gateway</label>
                      <VueMultiselect :allowEmpty="false" v-model="selectedGateway" class="multiselect-blue"
                                      :options="gatewayOptions"
                                      :close-on-select="true" placeholder="Select Payment Gateway" label="name"
                                      track-by="name"
                                      :show-labels="false"/>
                    </div>
                    <div class="text-danger" v-if="errors.refund.payment_gateway">{{ errors.refund.payment_gateway }}
                    </div>
                  </div>
                </div>

                <div class="col-12 col-sm-3"
                     v-if="isMethodOptionShow && isGatewayOptionsShow && isGatewayIdOptionsShow">
                  <div class="form-group">
                    <div class="controls">
                      <label>Gateway Id</label>
                      <VueMultiselect :allowEmpty="false" v-model="selectedGatewayId" class="multiselect-blue"
                                      :options="gatewayIdOptions"
                                      :close-on-select="true" placeholder="Select Payment Gateway" label="name"
                                      track-by="value"
                                      :show-labels="false"/>
                    </div>
                    <div class="text-danger" v-if="errors.refund.payment_gateway_id">{{
                        errors.refund.payment_gateway_id
                      }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <div class="controls">
                      <label>Reason</label>
                      <VueMultiselect :allowEmpty="false" v-model="selectedReason" class="multiselect-blue"
                                      :options="reasonOptions"
                                      :close-on-select="true" placeholder="Select reason" label="name" track-by="name"
                                      :show-labels="false"/>
                    </div>
                    <div class="text-danger" v-if="errors.refund.reason">{{ errors.refund.reason }}</div>
                  </div>
                </div>

                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <div class="controls">
                      <label>Refund Amount <span
                          v-show="placeholderAmount > 0">{{ `not more than $ ${placeholderAmount}` }}</span></label>
                      <input v-model.number="postRefundData.amount" type="number" class="form-control"
                             name="amount">
                    </div>
                    <div class="text-danger" v-if="errors.refund.amount">{{ errors.refund.amount }}</div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <div class="controls">
                      <label>Add Note</label>
                      <div style="margin-top: .5rem">
                        <div class="custom-control custom-switch custom-control-inline mb-1 d-flex align-items-center">
                          <div>
                            <input v-model="have_note" type="checkbox" class="custom-control-input" checked
                                   id="haveAppointmentNote">
                            <label class="custom-control-label mr-1" for="haveAppointmentNote"></label>
                          </div>
                          <span class="font-medium-1">{{ have_note ? "Yes" : "No" }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row" v-if="have_note">
                <div class="col-12 col-md-6"
                     v-if="currentUserTypeOptions !== undefined && currentUserTypeOptions.length > 2">
                  <label>User Type</label>
                  <VueMultiselect v-model="selectedUserType" class="" :allow-empty="false"
                                  :options="currentUserTypeOptions"
                                  :close-on-select="true" placeholder="Select Type" label="name" track-by="value"
                                  :show-labels="false"/>
                  <div class="text-danger" v-if="errors.refundNote.user_type">{{ errors.refundNote.user_type }}</div>
                </div>
                <!--                <div class="col-12 col-md-6">
                                  <label>Note Type</label>
                                  <VueMultiselect v-model="selectedRefundNoteType" class="" :allow-empty="false"
                                                  :options="refundNoteTypeOptions" :close-on-select="true" placeholder="Select Type"
                                                  label="name" track-by="value" :show-labels="false"/>
                                  <div class="text-danger" v-if="errors.refundNote.type">{{ errors.refundNote.type }}</div>
                                </div>-->

                <div class="col-12 ">
                  <div class="form-group">
                    <div class="controls">
                      <label>Note</label>
                      <QuillEditor v-model:content="description" toolbar="full" theme="snow"
                                   :placeholder="placeHolder" contentType="text"/>
                    </div>
                    <div class="text-danger" v-if="errors.refundNote.description">{{ errors.refundNote.description }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12 ">
                  <div class="d-flex justify-content-end">
                    <button @click="createSingleRefund" type="button" class="btn btn-primary update-todo px-4">
                      Add Refund
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </template>
  </AppLayout>
</template>

<style scoped>

</style>
